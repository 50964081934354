import getReaderUrl from '@/request-handlers/getReaderUrl';
// import getTodaysDate from '@/utils/getTodaysDate.js';
import getRegionList from '@/request-handlers/getRegionList';
import getEditionsList from '@/request-handlers/getEditionsList';
import FormatEditionTitle from '@/utils/formatEditionTitle';
import getPreferredEdition from '@/utils/getPreferredEdition';
import { setItem } from "@/utils/setCookie"
import { userBlockedEvent } from '@/utils/dataLayerEvents';

export default {
    login: () => window.tp.pianoId.show({
        loggedIn: () => { }
    }),
    logout: () => window.tp.pianoId.logout(),
    changeLoadingState: (context, loadingState) => context.commit('changeLoadingState', loadingState),
    changeDate: (context, payload) => context.commit('changeDate', payload),
    changeRegion: (context, payload) => context.commit('changeRegion', payload),
    changeEdition: (context, payload) => context.commit('changeEdition', payload),
    updateRegionsList: async (context) => {
        const result = await getRegionList(context.state.date, { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS })
            .then(res => res.json()).catch(() => [])
        // Change the state.regionList
        if (result.publications && result.publications.length > 0) {
            const list = result.publications
                //.filter(el => el.issues.web && el.issues.web.length > 0)
                .map(el => { return { id: el.id, title: FormatEditionTitle(el.title) } })

            // Need to sort the list, 'th_international' edition should be the first one
            list.sort((a, b) => {
                if (a.id === 'th_international') return -1
                else if (b.id === 'th_international') return 1
                return 0
            });

            context.commit('changeRegionList', list)
        }
        context.commit('changeLoadingState', false)
    },
    updateEditionsList: async (context) => {
        if(sessionStorage.selectededition != null){
            const cookieeditiondata = JSON.parse(sessionStorage.selectededition)
            const result = await getEditionsList(
                context.state.region.id,
                context.state.date,
                { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS }
            ).then(res => res.json()).catch(() => [])
            if (result.publications && result.publications.length > 0) {
            const list = result.publications
                .filter(el => el.issues.web && el.issues.web.length > 0)
                .map(el => {
                    return {
                        id: el.id,
                        title: FormatEditionTitle(el.title),
                        thumbnail: el.issues.web[0].coverImageThumbnailUri
                    }
                })
                console.log('cookieeditiondata',cookieeditiondata)
                const newList =[]
                newList.push(cookieeditiondata)
                list.forEach(i => {
                    i.id === cookieeditiondata.id ? console.log('same edition') : newList.push(i)
                });
            context.commit('changeEditionsList', newList)
            }    
        }
        else{
        const result = await getEditionsList(
            context.state.region.id,
            context.state.date,
            { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS }
        ).then(res => res.json()).catch(() => [])
        // Change the state.editionsList
        if (result.publications && result.publications.length > 0) {
            const list = result.publications
                .filter(el => el.issues.web && el.issues.web.length > 0)
                .map(el => {
                    return {
                        id: el.id,
                        title: FormatEditionTitle(el.title),
                        thumbnail: el.issues.web[0].coverImageThumbnailUri
                    }
                })
            context.commit('changeEditionsList', list)
        }
    }
    context.commit('changeLoadingState', false)
    },
    // change the selectedEdition as the first result from the jsonData
    updateSelectedEdition: (context) => context.commit('changeEdition', context.state.editionsList[0]),
    // change the selectedRegion as the first result from the jsonData
    updateSelectedRegion: (context) => context.commit('changeRegion', context.state.regionsList[0]),
    changeIframeUrl: async (context) => {
        context.commit("resetArticleViewUserEventFiredStatus");
        if (context.state.edition && context.state.edition.id) {
            context.commit('changeLoadingState', true)
            const readerUrl = await getReaderUrl(context.state.edition.id,
                context.state.date,
                { credentials: process.env.VUE_APP_INCLUDE_CREDENTIALS }
            )
            const readerUrlJson = await readerUrl.json()
            if (readerUrlJson.issues.web && readerUrlJson.issues.web.length > 0) {
                const url = readerUrlJson.issues.web[0].readerUrl
                const pageDetails = await fetch(readerUrlJson.issues.web[0].url+'OPS/cciobjects.json')
                const pageDetailsJson = await pageDetails.json()
                const pageId = pageDetailsJson.children.map(i => i.id)
                if(sessionStorage.pageInternalId != null){
                    const pageNumber = pageId.findIndex(i => i == sessionStorage.pageInternalId) >= 0 ? pageId.findIndex(i => i == sessionStorage.pageInternalId) + 1 : 1
                    const pageUrl = url.replace('/&page=1','/&page='+pageNumber)
                    context.commit('changeIframeUrl', pageUrl)
                    context.commit('changeLoadingState', false)
                }
                else{
                    context.commit('changeIframeUrl', url)
                    context.commit('changeLoadingState', false)
                }
            }
            else {
                context.commit('changeIframeUrl', 'null')
                context.commit('changeLoadingState', false)
            }
        }
        else {
            context.commit('changeIframeUrl', 'null')
            context.commit('changeLoadingState', false)
        }
    },
    setInitialIframeUrl: async (context) => {
        if (window.tp) {
            window.tp.pianoId.loadExtendedUser({
                extendedUserLoaded: (data) => {
                    const preferredEditionData = getPreferredEdition(data)
                    const region = preferredEditionData
                    // const edition = preferredEditionData
                    if(sessionStorage.selecteddate || sessionStorage.selectedregion || sessionStorage.selectededition){
                        if(sessionStorage.selecteddate){
                            context.dispatch('changeDate',sessionStorage.selecteddate)
                        }
                        if(sessionStorage.selectedregion){
                            context.dispatch('changeRegion', JSON.parse(sessionStorage.selectedregion))
                        }
                        else {
                            context.dispatch('changeRegion', region)
                        }
                        if(sessionStorage.selectededition){
                            context.dispatch('changeEdition', JSON.parse(sessionStorage.selectededition))
                        }
                    }
                    else{
                        context.dispatch('changeRegion', region)
                        // context.dispatch('changeEdition', edition)
                        context.dispatch('updateEditionsList')
                    }
                    if(sessionStorage.selectedregion == null && sessionStorage.selecteddate !== null ){
                        console.log('selectedregion',sessionStorage.selectedregion)
                        console.log('selecteddate',sessionStorage.selecteddate)
                        setItem('selectedregion',JSON.stringify(region))
                        // setItem('selectededition',JSON.stringify(edition))
                    }
                    if(sessionStorage.selectededition == null && sessionStorage.selecteddate !== null && sessionStorage.selectedregion !== null){
                        console.log('selectedregion',sessionStorage.selectedregion)
                        console.log('selecteddate',sessionStorage.selecteddate)
                        // setItem('selectededition',JSON.stringify(edition))
                    }
                    context.dispatch('updateRegionsList')
                    context.dispatch('changeIframeUrl')
                },
                formName: "MyAccountFields"
            })
        }
    },
    setArticleViewUserEventFired: (context) => context.commit("changeArticleViewUserEventFiredStatus"),
    checkUserSession: async (context) => {
        console.log("check user session action called...")
        await fetch(process.env.VUE_APP_SESSION_URL, {
            headers: {
                "X-Client-Auth": window?.tp?.pianoId?.getToken(),
            },
        })
            .then(res => {
                if (res.status === 401) {
                    return res.json()
                }

                return "valid-session"
            })
            .then(data => {
                if (data === "valid-session") return;
                context.state.session_devices_info = data
                context.state.session_limit_exceeded = true;
                userBlockedEvent();
            })
            .catch(err => {
                console.log("Error for session action...")
                console.log(err)
            })
    },
    resetSessionLimit: (context) => context.state.session_limit_exceeded = false,
}